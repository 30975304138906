import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useRouteMatch} from 'react-router-dom';
import {useLoading} from '../../hooks/use-loading';
import {useCustomBreadcrumbs} from '../../components/breadcrumbs/breadcrumbs-context';
import {ApiRequestException} from '../../api/axios-instance';
import {Preloader} from '../../components/preloader';
import {ErrorStub} from '../../components/error-stub';
import {Toolbar} from '../../components/card-toolbar/Toolbar';
import {Artist} from '../../api/DTOs/Artist';
import {ICONS} from '../../images/images';
import {ArtistAction, useArtistActionHandler} from './useArtistHandlerActions';
import {Svg} from '../../images/svg';
import {BootstrapSizes} from '../../../styles/bootstap-colors';
import {OtherVideos} from '../videos/video-page/other-videos';
import {ArtistPrivileges, ArtistRelation, useArtistsApi} from '../../hooks/apis/use-artists-api';

export const ArtistPage: React.FC<any> = () => {
  const intl = useIntl();
  const match = useRouteMatch<{ id: string }>();
  const artistId = match.params.id;

  const api = useArtistsApi();
  const [error, setError] = useState<string | null>(null);
  const [loadings, startLoading, stopLoading] = useLoading({
    page: true,
  });

  const [artist, setArtist] = useState<Artist | null>(null);
  const [relations, setRelations] = useState<ArtistRelation | null>(null);
  const [privileges, setPrivileges] = useState<ArtistPrivileges | null>(null);

  const {setBreadcrumb} = useCustomBreadcrumbs();

  useEffect(() => {
    fetchArtist().then();
  }, []);

  useEffect(() => {
    if (artist?.name) {
      setBreadcrumb(artist.name);
    }
  }, [artist?.name]);

  const fetchArtist = async () => {
    try {
      startLoading('page');
      const result = await api.get(artistId);
      setArtist(result.item);
      setRelations(result.relations);
      setPrivileges(result.privileges);
    } catch (e) {
      const err = e as ApiRequestException;
      if (err.errorMessage) {
        setError(err.errorMessage);
      } else {
        setError(e.message || intl.formatMessage({id: 'UNEXPECTED_ERROR'}));
      }
    } finally {
      stopLoading('page');
    }
  };
  const {handleArtistActions} = useArtistActionHandler(fetchArtist);

  if (loadings.page) {
    return <Preloader/>;
  }

  if (error) {
    return <ErrorStub error={error}/>;
  }

  if (artist === null) {
    return (
      <div className={'text-center'}>
        <FormattedMessage id={'NOT_FOUND'}/>
      </div>
    );
  }

  const renderButtons = () => {
    if (!artist) {
      return null;
    }

    return (
      <Toolbar
        items={[
          {
            visible: privileges?.can_edit ?? false,
            title: intl.formatMessage({id: 'EDIT'}),
            type: 'BUTTON',
            icon: ICONS.EDIT,
            className: 'btn btn-light-success font-weight-bolder',
            onClick: () => handleArtistActions({type: ArtistAction.EDIT, entity: artist}),
          },
          {
            visible: privileges?.can_delete ?? false,
            title: intl.formatMessage({id: 'DELETE'}),
            type: 'BUTTON',
            icon: ICONS.DELETE,
            className: 'btn btn-light-danger font-weight-bolder',
            onClick: () => handleArtistActions({type: ArtistAction.DELETE, entityId: artistId}),
          },
        ]}
      />
    );
  };

  return (
    <>
      <div className={`card card-custom gutter-b ribbon ribbon-top`}>
        <div className={`card-body`}>
          <div className={'d-flex justify-content-between flex-wrap'}>
            <div className={'d-flex align-items-center'}>
              {relations?.imageFile?.url ? (
                <img
                  width={100}
                  height={100}
                  src={relations?.imageFile?.url}
                  className={'rounded'}
                  alt={intl.formatMessage({id: 'LOGO'})}
                />
              ) : (
                <Svg src={ICONS.USER} size={BootstrapSizes.X8}/>
              )}
              <p className={'font-size-h4 font-weight-boldest mb-0 ml-2'}>{artist.name}</p>
            </div>
            <div className={'d-flex align-items-center flex-wrap justify-content-between'}>
              <div className={'d-flex flex-wrap align-items-center py-2'}>
                <div className={'d-flex align-items-center flex-wrap'}>
                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'CREATED_AT'}/>
                    </div>
                    <span className={'btn btn-sm btn-text btn-light-success text-uppercase font-weight-bolder'}>
                      {intl.formatDate(artist.created_at, {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                      })}
                    </span>
                  </div>

                  <div className={'d-flex justify-content-center flex-column mr-6'}>
                    <div className={'font-weight-bolder'}>
                      <FormattedMessage id={'UPDATED_AT'}/>
                    </div>
                    <div className={'btn btn-sm btn-text btn-light-primary text-uppercase font-weight-bolder'}>
                      {artist.updated_at
                        ? intl.formatDate(artist.updated_at, {
                          month: 'long',
                          year: 'numeric',
                          day: 'numeric',
                        })
                        : 'Not Available'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'separator separator-solid my-7'}/>
          {renderButtons()}
        </div>
      </div>

      <OtherVideos artists_ids={[artistId]} artistsAsString={artist?.name}/>
    </>
  );
};
